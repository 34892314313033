<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
      <el-form-item label="关键词名称：" prop="keyword">
        <el-input type="text" v-model="ruleForm.keyword"></el-input>
      </el-form-item>
      <el-form-item label="关联店铺：" prop="store">
        <el-input type="text" disabled v-model="ruleForm.store.store_name"></el-input>
        <el-button type="primary" @click="openShopDialog">选择</el-button>
      </el-form-item>
      <el-form-item label="关联商品：" prop="goods">
        <el-input type="text" disabled v-model="ruleForm.goods.goods_name"></el-input>
        <el-button type="primary" @click="ShowGoodsDialog">选择</el-button>
      </el-form-item>
    </el-form>
    <Preservation @preservation="Preservation"></Preservation>
    <!-- 选择商家弹框 -->
    <el-dialog title="选择商家" :visible.sync="pageFlag" width="52%">
      <div class="chooseShopBox">
        <div class="searchBox">
          <el-input v-model="searchShopName" placeholder="请输入商家名称"></el-input>
          <el-button type="primary" @click="searchOne">搜索</el-button>
        </div>
        <div class="showAllList" @scroll="loadPageMoreShop">
          <div class="showAllItem" v-for="(item, index) in allShopList" :key="index">
            <span>{{ item.store_name }}</span>
            <el-button type="text" @click="chooseStore(item)">选择</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 选择商品弹框 -->
    <el-dialog title="选择商品" :visible.sync="goodsDialog" width="52%">
      <div class="chooseGoodsBox">
        <div class="searchBox">
          <el-input v-model="goodsConfig.search" placeholder="请输入商家名称"></el-input>
          <el-button type="primary" @click="searchGoods">搜索</el-button>
        </div>
        <div class="showAllList" @scroll="loadPageMore">
          <div class="showAllItem" v-for="(item, index) in allGoodsList" :key="index">
            <div class="info">
              <img :src="item.goods_picture" alt="" />
              <div>{{ item.goods_name }}</div>
            </div>
            <div>￥{{ item.goods_price }}</div>
            <el-button type="text" @click="chooseGoods(item)">选择</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  created() {
    if (this.$route.query.id) {
      this.keyword_id = this.$route.query.id;
      this.getInfo();
    }
  },
  data() {
    function checkStore(rule, value, callback) {
      if (!value.id) {
        callback(new Error('请选择关联商家'));
      } else {
        callback();
      }
    }
    function checkGoods(rule, value, callback) {
      if (!value.id) {
        callback(new Error('请选择关联商家'));
      } else {
        callback();
      }
    }
    return {
      keyword_id: null,
      ruleForm: {
        keyword: '',
        store: {},
        goods: {},
      },
      rules: {
        keyword: [{ required: true, message: '请输入关键词', trigger: 'blur' }],
        store: [{ required: true, validator: checkStore, message: '请选择关联商家', trigger: 'change' }],
        goods: [{ required: true, validator: checkGoods, message: '请选择关联商品', trigger: 'change' }],
      },
      pageFlag: false,
      searchShopName: '',
      allShopList: [],
      page: 1,
      rows: 10,
      total: 0,
      allGoodsList: [],
      goodsDialog: false,
      goodsConfig: {
        page: 1,
        rows: 10,
        search: '',
        total: 0,
      },
    };
  },
  methods: {
    initShopDialog() {
      this.page = 1;
      this.allShopList = [];
      this.searchShopName = '';
    },
    openShopDialog() {
      this.initShopDialog();
      this.pageFlag = true;
      this.getAllShopList();
    },
    loadPageMoreShop(e) {
      if (e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight) {
        // 业务逻辑...
        if (this.total / this.rows > this.page) {
          this.page++;
          this.getAllShopList();
        }
      }
    },
    // 获取全部商家列表
    getAllShopList() {
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.searchShopName) obj.store_name = this.searchShopName;
      this.$axios.post(this.$api.store.storeList, obj).then(res => {
        if (res.code === 0) {
          this.allShopList = this.allShopList.concat(res.result.list);
          this.total = res.result.total_number;
        }
      });
    },
    searchOne() {
      this.page = 1;
      this.allShopList = [];
      this.getAllShopList();
    },
    chooseStore(data) {
      this.ruleForm.store = data;
      this.pageFlag = false;
    },
    searchGoods() {
      this.goodsConfig.page = 1;
      this.allGoodsList = [];
      this.getGoodsList();
    },
    ShowGoodsDialog() {
      this.goodsConfig = {
        search: '',
        page: 1,
        rows: 10,
        total: 0,
      };
      this.getGoodsList();
      this.goodsDialog = true;
    },
    loadPageMore(e) {
      if (e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight) {
        // 业务逻辑...
        if (this.goodsConfig.total / this.goodsConfig.rows > this.goodsConfig.page) {
          this.goodsConfig.page++;
          this.getGoodsList();
        }
      }
    },
    getGoodsList() {
      let obj = {
        page: this.goodsConfig.page,
        rows: this.goodsConfig.rows,
        status: 1,
      };
      if (this.goodsConfig.search) obj.goods_tag = this.goodsConfig.search;
      this.$axios.post(this.$api.goods.applyAndGoods, obj).then(res => {
        if (res.code == 0) {
          this.allGoodsList = this.allGoodsList.concat(res.result.list);
          this.goodsConfig.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    chooseGoods(data) {
      this.ruleForm.goods = data;
      this.goodsDialog = false;
    },
    Preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = {
            keyword: this.ruleForm.keyword,
            store_id: this.ruleForm.store.id,
            goods_id: this.ruleForm.goods.id,
          };
          let path = this.$api.Tikmall.addKeyword;
          if (this.keyword_id) {
            path = this.$api.Tikmall.editKeyword;
            obj.id = this.keyword_id;
          }
          this.$axios.post(path, obj).then(res => {
            if (res.code === 0) {
              this.$message.success('设置成功');
              this.$router.push('/extension/crossWebsite/keywords');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getInfo() {
      let that = this;
      this.$axios
        .post(this.$api.Tikmall.keywordInfo, {
          id: this.keyword_id,
        })
        .then(res => {
          if (res.code === 0) {
            this.ruleForm.keyword = res.result.keyword;
            this.ruleForm.store = {
              id: res.result.store_id,
              store_name: res.result.store_name,
            };
            this.ruleForm.goods = {
              id: res.result.goods_id,
              goods_name: res.result.goods_name,
            };
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  .el-form {
    .el-input {
      width: 500px;
    }
  }
  .chooseShopBox {
    .searchBox {
      display: flex;
      align-items: center;
    }
    .showAllList {
      height: 390px;
      width: 100%;
      overflow-y: auto;
      margin-top: 20px;
      .showAllItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
      }
      .showAllItem:hover {
        background-color: #faf5f5ef;
      }
    }
  }

  .chooseGoodsBox {
    .searchBox {
      display: flex;
      align-items: center;
    }
    .showAllList {
      height: 390px;
      width: 100%;
      overflow-y: auto;
      margin-top: 20px;
      .showAllItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 20px;
        margin: 6px 0;
        .info {
          display: flex;
          align-items: center;
          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
          }
          div {
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .showAllItem:hover {
        background-color: #faf5f5ef;
      }
    }
  }
}
</style>
